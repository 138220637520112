.section-left {
  color: #fff;
  padding: 32px;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100vw;
  .phone-desktop {
    position: absolute;
    top: 40px;
    right: 0;
  }
  .news {
    position: absolute;
    top: 40px;
    left: 0;
  }
  .section-left-content {
    position: absolute;
    top: 40%;
    left: 24px;
    .promotion {
      text-align: left;
      width: 230px;
    }
  }
}

@media (max-width: 900px) {
  .section-left {
    padding: 20px;
    width: 100%;
    height: 140px;
    overflow: hidden;
    .phone-mobile1 {
      position: absolute;
      right: 27px;
      bottom: 0;
      max-width: 200px;
    }
    .phone-mobile2 {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 200px;
    }
    .section-left-content {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }
}

@media (min-width: 900px) {
  .section-left {
    padding: 16px;
    text-align: center;
    width: 360px;
    height: 100vh;
    .section-left-content {
      width: 100%;
      .close {
        top: 16px;
        right: 16px;
      }
      .promotion {
        .promotional-offer {
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 0.3em;
          margin-bottom: 40px;
        }
      }
    }
  }
}
