.forgot-password-page {
  display: flex;
  flex-direction: column;
  background-color: white;

  .section-main {
    min-height: 100%;
    flex-grow: 1;
    overflow: auto;
    padding: 16px;
  }
}

@media (min-width: 900px) {
  .forgot-password-page {
    flex-direction: row;
  }
}
