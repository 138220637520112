h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a,
button {
  text-transform: none;
}

a[role="button"] {
  text-decoration: none !important;
}

:focus {
  outline-color: transparent;
}

button:focus {
  outline: none;
}

button,
[role="button"] {
  cursor: pointer;
}

img,
video {
  max-width: 100%;
  height: auto;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.MuiButton-root {
  // border-radius: 8px !important;
}

input,
textarea,
select {
  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }
}
