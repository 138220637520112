.tooltip-background {
  background-color: #dddddd !important;
  color: #000000 !important;
}

.tooltip-caret {
  color: #dddddd !important;
}

.tooltip-link {
  color: #15abe6 !important;
}
