.invitation-page {
  display: flex;
  flex-direction: column;
  background-color: white;

  .section-left {
    background: linear-gradient(164.12deg, #15abe6 0%, #625df5 100%);
    color: #fff;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    position: relative;
    height: 100%;
    width: 100vw;

    .section-left-content {
      width: 600px;
      position: relative;

      .close {
        position: absolute;
        top: -16px;
        right: -16px;
      }

      .promotion {
        .promotional-offer {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          margin-bottom: 12px;
        }
      }
    }
  }

  .section-main {
    min-height: 100%;
    flex-grow: 1;
    overflow: auto;
    padding: 16px;
  }
}

@media (min-width: 900px) {
  .invitation-page {
    flex-direction: row;

    .section-left {
      width: 400px;
      height: 100vh;
      text-align: center;

      .section-left-content {
        width: 100%;
        position: unset;

        .close {
          top: 16px;
          right: 16px;
        }

        .promotion {
          .promotional-offer {
            font-size: 14px;
            font-weight: 900;
            letter-spacing: 0.3em;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
